import React, { useMemo } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Scrollbar, Navigation } from 'swiper';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import ProductInfo from './ProductInfo';
import SliderArrowSVG from '../../assets/icons/slider-arrow.svg';

import { IProductPairsProps } from './Types';

import { IProductInfo } from '../../templates/Types';

import './ProductPairs.scss';

SwiperCore.use([Pagination, Scrollbar, Navigation]);

const ProductPairs: React.FC<IProductPairsProps> = ({ productPairs }): JSX.Element => {
  const breakpoints = useBreakpoint();
  const productPairsSorted: IProductInfo[] = useMemo(
    () => productPairs.sort(good => (good.productAvailable.value === 'IN_STOCK' ? -1 : 1)),
    [productPairs]
  );

  return (
    <div className="product-pairs" id="pairs">
      <div className="product-pairs-wrapper">
        <h2 className="product-pairs-header">Разом з цим товаром купують</h2>
        <Swiper
          slidesPerView={breakpoints.laptops ? 4 : 1}
          spaceBetween={30}
          direction="horizontal"
          centeredSlides={false}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          className="swiper"
          scrollbar={{
            el: '.swiper-scrollbar',
            draggable: true,
            dragSize: 27,
          }}
        >
          {productPairsSorted.map(
            ({
              name: { value: name },
              id,
              description: { value: description },
              price: { value: price },
              price2,
              price3,
              price4,
              measurment,
              measurment2,
              measurment3,
              measurment4,
              productAvailable,
              stock,
              link,
              images: { value: images },
              image: { value: image },
              specifications,
              code: { value: code },
            }) => (
              <SwiperSlide>
                <ProductInfo
                  name={name}
                  description={description}
                  price={price}
                  price2={price2?.value || ''}
                  price3={price3?.value || ''}
                  price4={price4?.value || ''}
                  measurment={measurment?.value || ''}
                  measurment2={measurment2?.value || ''}
                  measurment3={measurment3?.value || ''}
                  measurment4={measurment4?.value || ''}
                  images={images}
                  image={image}
                  productAvailable={productAvailable?.value || null}
                  code={code}
                  link={link.value}
                  stock={stock?.value || ''}
                  id={id}
                  specifications={specifications?.value?.data || null}
                  type="good"
                  key={id}
                />
              </SwiperSlide>
            )
          )}
        </Swiper>
        <div className="swiper-scrollbar">
          <div className="swiper-scrollbar-drag" />
        </div>
        <div className="swiper-next">
          <SliderArrowSVG />
        </div>
        <div className="swiper-prev">
          <SliderArrowSVG />
        </div>
      </div>
    </div>
  );
};

export default ProductPairs;
