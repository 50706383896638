import React from 'react';

import { IProductSpecificationsProps } from './Types';

import './ProductSpecifications.scss';

const ProductSpecifications: React.FC<IProductSpecificationsProps> = ({ data, title, description }): JSX.Element => (
  <div className="product-specifications" id="specifications">
    <div className="product-specifications-description">
      <h2 className="product-specifications-description__title">Опис</h2>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
    {data && (
      <div className="product-specifications-wrapper">
        <h2 className="product-specifications__title">Характеристики</h2>
        <div className="product-specifications__items">
          {data &&
            Object.keys(data).map(key => (
              <div key={key} className="specification">
                <div className="specification-characteristic">{key}</div>
                <div className="specification-value">{data[key]}</div>
              </div>
            ))}
        </div>
      </div>
    )}
  </div>
);

export default ProductSpecifications;
